<template>
    <section :id="sliceId" class="TitleTextBlock" :class="[
        'TitleTextBlock--align-' + slice.primary.text_alignment
            ? 'TitleTextBlock--align-' +
            slice.primary.text_alignment.toLowerCase()
            : 'TitleTextBlock--align-left',
        {
            'TitleTextBlock--hide-body-on-mobile':
                slice.primary.hide_description_text_on_mobile,
            'TitleTextBlock--as-page-hero': index === 0,
        },
    ]" data-speed="clamp(1.1)">
        <div v-if="slice.primary.optional_logo?.url" class="TitleTextBlock__image-wrapper">
            <SmartImage :url="slice.primary.optional_logo.url" :with-placeholder="false"
                :width="slice.primary.optional_logo.dimensions.width"
                :height="slice.primary.optional_logo.dimensions.height"
                :srcs="[{ width: 400 }, { width: 800 }, { width: 1200 }]" positioning="relative"
                class="TitleTextBlock__image" />
        </div>
        <FillTextEffect v-if="slice.primary.title.length" class="TitleTextBlock__title" :id="sliceId"
            bottom-position="bottom" :trigger-container="'#' + sliceId" :filled-by-default="index === 0">
            <PrismicRichText v-if="slice.primary.title.length" :field="slice.primary.title" :id="sliceId" />
        </FillTextEffect>
        <PrismicRichText v-if="slice.primary.description.length" :field="slice.primary.description"
            class="TitleTextBlock__desc" />
        <PrismicRichText v-if="slice.primary.body.length" :field="slice.primary.body" class="TitleTextBlock__body" />
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.TitleTextBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-') + '-title-text-block'
</script>

<style lang="scss">
.theme--white {
    .TitleTextBlock {
        color: var(--black);
    }

    .TitleTextBlock__image-wrapper {
        filter: brightness(0);
    }
}

.TitleTextBlock {
    @include grid($columns: 22);
    grid-template-rows: auto;
    grid-template-areas: 'logo' 'title' 'desc' 'body';
    grid-column: 2 / span 22;
    padding-top: 8rem;

    @include small-only {
        margin-top: var(--promo-bar-height);
    }

    @include large-up {
        padding-top: 11.25rem;
    }

    .TitleTextBlock__title {
        @include large-up {
            grid-column: 1 / span 14;
        }
    }

    .TitleTextBlock__desc {
        @include large-up {
            grid-column: 1 / span 14;
        }
    }
}

.TitleTextBlock--hide-body-on-mobile {
    .TitleTextBlock__body {
        @include small-only {
            display: none;
        }
    }
}

.TitleTextBlock--as-page-hero {
    @include small-only {
        padding-top: 4rem;
    }

    .TitleTextBlock__image-wrapper {
        height: auto;
    }
}

.TitleTextBlock--align-right {
    text-align: right;
    justify-items: flex-end;

    .TitleTextBlock__image-wrapper {
        grid-column: 21 / span 2;
    }

    .TitleTextBlock__title {
        @include large-up {
            grid-column: 9 / span 14;
        }
    }

    .TitleTextBlock__desc,
    .TitleTextBlock__body {
        @include large-up {
            grid-column: 9 / span 14;
        }
    }
}

.TitleTextBlock--align-center {
    text-align: center;
    justify-items: center;

    .TitleTextBlock__image-wrapper {
        grid-column: 1 / span 24;
    }

    .TitleTextBlock__title {
        @include large-up {
            grid-column: 5 / span 14;
        }
    }

    .TitleTextBlock__desc,
    .TitleTextBlock__body {
        @include large-up {
            grid-column: 5 / span 14;
        }
    }
}

.TitleTextBlock--align-left {
    text-align: left;
    justify-items: flex-start;

    .TitleTextBlock__image-wrapper {
        grid-column: 1 / span 2;
    }

    .TitleTextBlock__title {
        @include large-up {
            grid-column: 1 / span 14;
        }
    }

    .TitleTextBlock__desc,
    .TitleTextBlock__body {
        grid-column: 1 / span 22;

        @include large-up {
            grid-column: 1 / span 12;
        }
    }

    .TitleTextBlock__desc {
        @include large-up {
            grid-column: 1 / span 14;
        }
    }
}

.TitleTextBlock__title {
    grid-column: 1 / span 22;
    grid-row: title;

    h2 {
        @include heading-2;
    }

    h3 {
        @include heading-3;
    }

    h4 {
        @include heading-4;
    }

    h5 {
        @include heading-5;
    }

    h6 {
        @include heading-6;
    }
}

.TitleTextBlock__desc {
    grid-row: desc;

    p {
        @include small-only {
            @include body-md;
        }

        @include medium-up {
            @include body-lg;
        }

        letter-spacing: -0.4px;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    a {
        color: var(--lime-green);
    }
}

.TitleTextBlock__body {
    grid-row: body;

    p {
        @include body-sm;
    }

    a {
        color: var(--lime-green);
    }
}

.TitleTextBlock__image-wrapper {
    grid-row: logo;
    height: 100px;
}

.TitleTextBlock__image {
    height: 100%;
}

.TitleTextBlock__desc,
.TitleTextBlock__body {
    grid-column: 1 / span 22;

    @include large-up {
        grid-column: 1 / span 12;
    }
}

.TitleTextBlock__title,
.TitleTextBlock__desc {
    max-width: 50rem;
}

.TitleTextBlock__title,
.TitleTextBlock__desc,
.TitleTextBlock__body,
.TitleTextBlock__image-wrapper {
    margin-bottom: 1.25rem;
}
</style>
